export const environment = {
  production: false,

  BASE_URL: 'https://d1z7etlyzwhf4l.cloudfront.net/',
  LOGIN_URL: 'https://reghubpreprod.auth.eu-west-1.amazoncognito.com/login?client_id=7r8jgvl7s073oec048s9brh0bf&response_type=token&scope=openid+profile&redirect_uri=https%3A%2F%2Fd1z7etlyzwhf4l.cloudfront.net%2F',
  ALLOWED_PROFILES: [
    "InfoDir-OBSS-DEV-Supportportal-DBB-OrphanTrade"
  ]
};

/**
 *  uk dev: LOGIN_URL: 'https://reghubdev.auth.eu-west-1.amazoncognito.com/login?client_id=6chjv6otg6bnb23vrritdou90l&response_type=token&scope=openid+profile&redirect_uri=https%3A%2F%2Fd1z7etlyzwhf4l.cloudfront.net%2F',
 *  uk preprod LOGIN_URL: 'https://reghubpreprod.auth.eu-west-1.amazoncognito.com/login?client_id=7r8jgvl7s073oec048s9brh0bf&response_type=token&scope=openid+profile&redirect_uri=https%3A%2F%2Fd1z7etlyzwhf4l.cloudfront.net%2F',
 *  sg dev:     LOGIN_URL: 'https://reghubdev.auth.ap-southeast-1.amazoncognito.com/login?client_id=3tsf68854h9iuv0l0goote2hd1&response_type=token&scope=openid+profile&redirect_uri=https%3A%2F%2Fd1z7etlyzwhf4l.cloudfront.net%2F',

 */
